import React from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";

export default function WalletConnect({ setProvider }) {
  const provider = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    },
  });

  provider.on("accountsChanged", (accounts) => {
    if (accounts.length === 0) {
      setProvider(null, false);
    }
  });

  const initiateWalletConnect = async () => {
    try {
      await provider.enable();
      setProvider(provider, true);
    } catch {
      console.log("Unable to connect wallet");
    }
  };

  return (
    <button
      className="walletConnectButton"
      onClick={() => initiateWalletConnect()}
    >
      WalletConnect
    </button>
  );
}
