import React, { useState } from "react";
import MetamaskButton from "../../components/MetamaskButton";
import MintForm from "../mintForm/MintForm";
import Title from "../title/Title";
import "./appContainer.css";
export default function AppContainer() {
  const [metamaskConnected, setMetamaskConnected] = useState(false);

  return (
    <div className="appContainer">
      <MintForm />
    </div>
  );
}
