import { create as ipfsHttpClient } from "ipfs-http-client";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { v4 as uuidv4 } from "uuid";
import Web3 from "web3";
import MetamoneyContract from "../../contracts/Metamoney.json";
import metamoneyBlankBillImage from "../../images/MetamoneyBlankBillImage.png";
import "./mintForm.css";
import Font from "react-font";
import html2canvas from "html2canvas";
import MetamaskButton from "../MetamaskButton";
import "../appContainer/appContainer.css";
import { ethers } from "ethers";
import WalletConnect from "../WalletConnect";

export default function MintForm() {
  const [valueOfBill, setValueOfBill] = useState("0");
  const [amountOfBills, setAmountOfBills] = useState("0");
  const [notes, setNotes] = useState("");

  const [billEdition, setBillEdition] = useState("");
  const [billSerialNum, setBillSerialNum] = useState("");

  const [contract, setContract] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [connectedNetwork, setConnectedNetwork] = useState(null);
  const [web3, setWeb3] = useState(null);

  const [loading, setLoading] = useState(false);

  const [showingResponseMessage, setShowingResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [metamaskConnected, setMetamaskConnected] = useState(false);

  const [WalletConnectProvider, setWalletConnectProvider] = useState(null);

  const [walletConnected, setWalletConnected] = useState(false);

  const client = ipfsHttpClient("https://ipfs.infura.io:5001/api/v0");

  const contractAddress = "0xdFbbB767b1cEb45438b06203EE3F34A613134a1e";

  useEffect(async () => {
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    }
    setWeb3(web3);

    let currentNetwork;
    if (web3) {
      currentNetwork = await web3.eth.net.getId();

      if (currentNetwork === 56) {
        setConnectedNetwork("BNB");
      }

      const metamaskAccounts = await web3.eth.getAccounts();
      setAccounts(metamaskAccounts);
    }

    try {
      const instance = new web3.eth.Contract(
        MetamoneyContract.abi,
        contractAddress
      );

      setContract(instance);
    } catch (error) {
      console.log("error:", error);
    }
  }, []);

  if (window.ethereum) {
    window.ethereum.on("networkChanged", (networkId) => {
      if (networkId === "56") {
        setConnectedNetwork("BNB");
      }
    });
  }

  const getCanvasBlob = (canvas) => {
    return new Promise(function (resolve, reject) {
      canvas.toBlob(function (blob) {
        resolve(blob);
      });
    });
  };

  const uploadImages = async () => {
    setLoading(true);
    const tokenURIs = [];
    var counter = 0;

    var currentSupply = await contract.methods.getSupply().call();

    for (let i = 0; i < parseInt(amountOfBills); i++) {
      counter++;
      const edition = parseInt(currentSupply) + i + 1;
      setBillEdition(edition);
      const serialNum = uuidv4();
      setBillSerialNum(serialNum);

      html2canvas(document.querySelector("#capture")).then(async (canvas) => {
        const ctx = canvas.getContext("2d");
        const canvasBlob = await getCanvasBlob(canvas);
        const addedFile = await client.add(canvasBlob);
        const fileUrl = `https://ipfs.infura.io/ipfs/${addedFile.path}`;

        const metaData = JSON.stringify({
          name: `${valueOfBill} METAMONY - BUSD`,
          description: "NFTfied currency 1:1 to BUSD",
          image: fileUrl,
          dna: serialNum,
          edition: edition,
          date: new Date(),
          attributes: [
            {
              trait_type: "BOUND",
              value: "BUSD",
            },
            {
              trait_type: "SYMBOL",
              value: "BUSDNFT",
            },
            {
              trait_type: "VALUE",
              value: valueOfBill,
            },
            {
              trait_type: "NOTES",
              value: notes,
            },
          ],
        });

        const addedMetaData = await client.add(metaData);
        const url = `https://ipfs.infura.io/ipfs/${addedMetaData.path}`;

        tokenURIs.push(url);
      });
    }

    initiateMint(tokenURIs);
  };

  const [defaultAccount, setDefaultAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [ethersContract, setEthersContract] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // connectWalletHandler();
  }, []);

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    updateEthers();
  };

  const updateEthers = () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    let tempContract = new ethers.Contract(
      contractAddress,
      MetamoneyContract.abi,
      tempSigner
    );
    setEthersContract(tempContract);
  };

  const tokenAbi = [
    {
      constant: false,
      inputs: [],
      name: "disregardProposeOwner",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_spender", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "assetProtectionRole",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "r", type: "bytes32[]" },
        { name: "s", type: "bytes32[]" },
        { name: "v", type: "uint8[]" },
        { name: "to", type: "address[]" },
        { name: "value", type: "uint256[]" },
        { name: "fee", type: "uint256[]" },
        { name: "seq", type: "uint256[]" },
        { name: "deadline", type: "uint256[]" },
      ],
      name: "betaDelegatedTransferBatch",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "sig", type: "bytes" },
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "fee", type: "uint256" },
        { name: "seq", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
      name: "betaDelegatedTransfer",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_from", type: "address" },
        { name: "_to", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "initializeDomainSeparator",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ name: "", type: "uint8" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "unpause",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_addr", type: "address" }],
      name: "unfreeze",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "claimOwnership",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_newSupplyController", type: "address" }],
      name: "setSupplyController",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "paused",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "_addr", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "initialize",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "pause",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "getOwner",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "target", type: "address" }],
      name: "nextSeqOf",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_newAssetProtectionRole", type: "address" }],
      name: "setAssetProtectionRole",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_addr", type: "address" }],
      name: "freeze",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "owner",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_newWhitelister", type: "address" }],
      name: "setBetaDelegateWhitelister",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_value", type: "uint256" }],
      name: "decreaseSupply",
      outputs: [{ name: "success", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "_addr", type: "address" }],
      name: "isWhitelistedBetaDelegate",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_to", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_addr", type: "address" }],
      name: "whitelistBetaDelegate",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_proposedOwner", type: "address" }],
      name: "proposeOwner",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_value", type: "uint256" }],
      name: "increaseSupply",
      outputs: [{ name: "success", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "betaDelegateWhitelister",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "proposedOwner",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_addr", type: "address" }],
      name: "unwhitelistBetaDelegate",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { name: "_owner", type: "address" },
        { name: "_spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_addr", type: "address" }],
      name: "wipeFrozenAddress",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "EIP712_DOMAIN_HASH",
      outputs: [{ name: "", type: "bytes32" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "_addr", type: "address" }],
      name: "isFrozen",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "supplyController",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "reclaimBUSD",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "from", type: "address" },
        { indexed: true, name: "to", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "owner", type: "address" },
        { indexed: true, name: "spender", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "currentOwner", type: "address" },
        { indexed: true, name: "proposedOwner", type: "address" },
      ],
      name: "OwnershipTransferProposed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "oldProposedOwner", type: "address" }],
      name: "OwnershipTransferDisregarded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "oldOwner", type: "address" },
        { indexed: true, name: "newOwner", type: "address" },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    { anonymous: false, inputs: [], name: "Pause", type: "event" },
    { anonymous: false, inputs: [], name: "Unpause", type: "event" },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "addr", type: "address" }],
      name: "AddressFrozen",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "addr", type: "address" }],
      name: "AddressUnfrozen",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "addr", type: "address" }],
      name: "FrozenAddressWiped",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "oldAssetProtectionRole", type: "address" },
        { indexed: true, name: "newAssetProtectionRole", type: "address" },
      ],
      name: "AssetProtectionRoleSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "to", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "SupplyIncreased",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "from", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "SupplyDecreased",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "oldSupplyController", type: "address" },
        { indexed: true, name: "newSupplyController", type: "address" },
      ],
      name: "SupplyControllerSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "from", type: "address" },
        { indexed: true, name: "to", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
        { indexed: false, name: "seq", type: "uint256" },
        { indexed: false, name: "fee", type: "uint256" },
      ],
      name: "BetaDelegatedTransfer",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "oldWhitelister", type: "address" },
        { indexed: true, name: "newWhitelister", type: "address" },
      ],
      name: "BetaDelegateWhitelisterSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "newDelegate", type: "address" }],
      name: "BetaDelegateWhitelisted",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "oldDelegate", type: "address" }],
      name: "BetaDelegateUnwhitelisted",
      type: "event",
    },
  ];

  const tokenAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";

  const initiateMint = async (tokenURIs) => {
    const fee = (amountOfBills * valueOfBill) / 100;
    const price = amountOfBills * valueOfBill + fee;

    let provider = new ethers.providers.Web3Provider(window.ethereum);
    if (WalletConnectProvider) {
      console.log("provider:", provider);
      provider = new ethers.providers.Web3Provider(WalletConnectProvider);
    }

    const signer = provider.getSigner();
    const ethersContract = new ethers.Contract(
      contractAddress,
      MetamoneyContract.abi,
      signer
    );

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const tokenAmount = Math.round(price * 10 ** 18);
    const approveResult = await tokenContract.approve(
      contractAddress,
      tokenAmount.toString()
    );

    approveResult.wait();

    ethersContract
      .mint(amountOfBills, valueOfBill, tokenURIs)
      .then((res) => {
        setLoading(false);
        console.log("res", res);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  return (
    <div className="mintForm">
      <Font family="Libre Baskerville">
        <div className="formTitle">Metamoney</div>
      </Font>
      <div className="mintForm-inputs-section">
        <div className="mintForm-input-container">
          <label className="mintForm-label">Value of bill</label>
          <input
            className="mintForm-input"
            type="number"
            min={1}
            max={100000000}
            onChange={(e) => {
              setValueOfBill(e.target.value);
            }}
          />
        </div>

        <div className="mintForm-input-container">
          <label className="mintForm-label">Amount of bills</label>
          <input
            className="mintForm-input"
            type="number"
            min={1}
            max={100}
            onChange={(e) => {
              setAmountOfBills(e.target.value);
            }}
          />
        </div>

        <div className="mintForm-input-container">
          <label className="mintForm-label">Notes</label>
          <input
            className="mintForm-input"
            type="text"
            maxLength={100}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </div>
      </div>
      {parseInt(valueOfBill) > 100000000 && (
        <div className="max-error-message">
          The max value per bill is 100000000 .
        </div>
      )}
      {parseInt(amountOfBills) > 100 && (
        <div className="max-error-message">
          The max amount of bills per mint is 100.
        </div>
      )}
      {(valueOfBill !== "") &
      (amountOfBills !== "") &
      (parseInt(valueOfBill) < 100000001) &
      (parseInt(amountOfBills) < 101) ? (
        <div className="mintForm-price-container">
          <label className="mintForm-price-label">Price:</label>
          <div className="mintForm-price">
            {parseInt(valueOfBill) * parseInt(amountOfBills)}
          </div>
        </div>
      ) : null}
      <div id="capture" className="capture-area">
        <div className="valueOfBill-positionOne">{valueOfBill}</div>
        <div
          className="billEdition-positionTwo"
          style={{
            position: "absolute",
            marginTop: "175px",
            marginLeft: "375px",
            color: "red",
          }}
        >
          {billEdition}
        </div>
        <div className="valueOfBill-positionThree">{valueOfBill}</div>
        <div
          className="billSerialNum-positionFour"
          style={{
            position: "absolute",
            marginTop: "380px",
            marginLeft: "180px",
            color: "red",
            fontSize: "0.4rem",
          }}
        >
          {billSerialNum}
        </div>

        <img className="imageOne" src={metamoneyBlankBillImage} />
      </div>
      {(valueOfBill !== "0") &
      (valueOfBill !== "") &
      (parseInt(valueOfBill) < 100000001) &
      (amountOfBills !== "") &
      (amountOfBills !== "0") &
      (parseInt(amountOfBills) < 101) &
      (web3 !== null) &
      (connectedNetwork === "BNB") ? (
        <button className="mintForm-button" onClick={uploadImages}>
          {loading ? <Spinner animation="border" /> : " Mint"}
        </button>
      ) : null}
      {connectedNetwork !== "BNB" && (
        <div>Make sure you're connected to the Binance Smart Chain!</div>
      )}
      {showingResponseMessage && (
        <div className="responseMessage-container">
          <div className="responseMessage">{responseMessage}</div>
        </div>
      )}

      {walletConnected ? null : (
        <div className="connect-buttons-container">
          <WalletConnect
            setProvider={(provider, value) => {
              setWalletConnected(value);
              setWalletConnectProvider(provider);
            }}
          />

          <MetamaskButton
            connect={(value) => {
              setWalletConnected(value);
              setMetamaskConnected(value);
            }}
          />
        </div>
      )}
    </div>
  );
}
